.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* :root {
  --amplify-font-family:	'Amazon Ember', 'Helvetica Neue Light', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif';
  --amplify-text-xxs:	0.75rem;
  --amplify-text-xs:	0.81rem;
  --amplify-text-sm:	0.875rem;
  --amplify-text-md:	1rem;
  --amplify-text-lg:	1.5rem;
  --amplify-text-xl:	2rem;
  --amplify-text-xxl:	2.5rem;
  --amplify-primary-color:	#ff9900;
  --amplify-primary-contrast:	var(--amplify-white);
  --amplify-primary-tint:	#ffac31;
  --amplify-primary-shade:	#e88b01;
  --amplify-secondary-color:	#152939;
  --amplify-secondary-contrast:	var(--amplify-white);
  --amplify-secondary-tint:	#31465f;
  --amplify-secondary-shade:	#1F2A37;
  --amplify-tertiary-color:	#5d8aff;
  --amplify-tertiary-contrast:	var(--amplify-white);
  --amplify-tertiary-tint:	#7da1ff;
  --amplify-tertiary-shade:	#537BE5;
  --amplify-grey: #828282;
  --amplify-light-grey:	#c4c4c4;
  --amplify-white: #ffffff;
  --amplify-red: #dd3f5b;
} */